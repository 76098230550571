@font-face {
  font-family: 'lato';
  src: url('../fonts/lato-regular.woff2') format('woff2'), url('../fonts/lato-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latoblack';
  src: url('../fonts/lato-black.woff2') format('woff2'), url('../fonts/lato-black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latoblack_italic';
  src: url('../fonts/lato-blackitalic.woff2') format('woff2'), url('../fonts/lato-blackitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latobold';
  src: url('../fonts/lato-bold.woff2') format('woff2'), url('../fonts/lato-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latobold_italic';
  src: url('../fonts/lato-bolditalic.woff2') format('woff2'), url('../fonts/lato-bolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latohairline';
  src: url('../fonts/lato-hairline.woff2') format('woff2'), url('../fonts/lato-hairline.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latohairline_italic';
  src: url('../fonts/lato-hairlineitalic.woff2') format('woff2'), url('../fonts/lato-hairlineitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latoheavy';
  src: url('../fonts/lato-heavy.woff2') format('woff2'), url('../fonts/lato-heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latoheavy_italic';
  src: url('../fonts/lato-heavyitalic.woff2') format('woff2'), url('../fonts/lato-heavyitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latoitalic';
  src: url('../fonts/lato-italic.woff2') format('woff2'), url('../fonts/lato-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latolight';
  src: url('../fonts/lato-light.woff2') format('woff2'), url('../fonts/lato-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latolight_italic';
  src: url('../fonts/lato-lightitalic.woff2') format('woff2'), url('../fonts/lato-lightitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latomedium';
  src: url('../fonts/lato-medium.woff2') format('woff2'), url('../fonts/lato-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latomedium_italic';
  src: url('../fonts/lato-mediumitalic.woff2') format('woff2'), url('../fonts/lato-mediumitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latosemibold';
  src: url('../fonts/lato-semibold.woff2') format('woff2'), url('../fonts/lato-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latosemibold_italic';
  src: url('../fonts/lato-semibolditalic.woff2') format('woff2'), url('../fonts/lato-semibolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latothin';
  src: url('../fonts/lato-thin.woff2') format('woff2'), url('../fonts/lato-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'latothin_italic';
  src: url('../fonts/lato-thinitalic.woff2') format('woff2'), url('../fonts/lato-thinitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bcalphapiperb_regular';
  src: url('../fonts/bcalphapiperb-regular.woff2') format('woff2'), url('../fonts/bcalphapiperb-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bcalphapiperb_bold';
  src: url('../fonts/bcalphapiperb-bold.woff2') format('woff2'), url('../fonts/bcalphapiperb-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sugo_pro_display_regular_trial';
  src: url('../fonts/sugo-pro-display-regular-trial.woff2') format('woff2'),
    url('../fonts/sugo-pro-display-regular-trial.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// font-family: 'Open Sans', sans-serif;
// font-family: 'Poppins', sans-serif;
