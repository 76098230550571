@mixin bp-mobile {
  @media only screen and (max-width: 415px) {
    @content;
  }
}

@mixin bp-tablet {
  @media only screen and (min-width: 416px) and (max-width: 830px) {
    @content;
  }
}

@mixin bp-tablet-max {
  @media only screen and (max-width: 830px) {
    @content;
  }
}

@mixin bp-desktop-min {
  @media only screen and (min-width: 831px) {
    @content;
  }
}

@mixin trans($type: all, $time: 0.25s) {
  transition: $type $time ease 0s;
}

@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    @error 'Value for `to-number` should be a number or a string.';
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: (
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
  );

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if (index(map-keys($numbers), $character) or $character == '.') {
      @if $character == '.' {
        $digits: 1;
      } @else if $digits == 0 {
        $result: $result * 10 + map-get($numbers, $character);
      } @else {
        $digits: $digits * 10;
        $result: $result + map-get($numbers, $character) / $digits;
      }
    }
  }

  @return if($minus, -$result, $result);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if(
    $index,
    str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace),
    $string
  );
}

/*
 * Usage: calc-based(number-reference, vw or vh)
 */
@function calc-based($number, $axis: vw) {
  $base: 0;

  $number: quote($number);
  $number: str-replace($number, 'px');
  $number: str-replace($number, 'em');
  $number: str-replace($number, 'rem');
  $number: str-replace($number, 'pt');
  $number: str-replace($number, 'vw');
  $number: str-replace($number, 'vh');
  $number: to-number($number);

  @if $axis == vh {
    $base: 640;
  } @else {
    $base: 360;
  }

  $myCalc: ($number * 100) / $base;

  @return #{$myCalc}#{$axis};
}

// Fonts
@mixin font-lato {
  font-family: 'lato', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-black {
  font-family: 'latoblack', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-black-italic {
  font-family: 'latoblack_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-bold {
  font-family: 'latobold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-bold-italic {
  font-family: 'latobold_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-hairline {
  font-family: 'latohairline', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-hairline-italic {
  font-family: 'latohairline_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-heavy {
  font-family: 'latoheavy', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-heavy-italic {
  font-family: 'latoheavy_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-italic {
  font-family: 'latoitalic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-light {
  font-family: 'latolight', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-light-italic {
  font-family: 'latolight_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-medium {
  font-family: 'latomedium', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-medium-italic {
  font-family: 'latomedium_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-semibold {
  font-family: 'latosemibold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-semibold-italic {
  font-family: 'latosemibold_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-thin {
  font-family: 'latothin', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-lato-thin-italic {
  font-family: 'latothin_italic', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-bc-alphapipe-rb-regular {
  font-family: 'bcalphapiperb_regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-bc-alphapipe-rb-bold {
  font-family: 'bcalphapiperb_bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@mixin font-sugo-pro-display-regular-trial {
  font-family: 'sugo_pro_display_regular_trial', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}
