@import '../../assets/styles/mixin';

aside {
  width: 162px;

  nav {
    display: block;
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      list-style: none;
      padding: 40px 0 0;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 40px;
      }
    }
  }

  .nav-link {
    color: #949898;
    font-size: 25px;
    font-weight: normal;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      color: #d66a39;
    }
  }
}
