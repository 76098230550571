@import '../../assets/styles/mixin';

html body header {
  padding-left: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  div.logo {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1 {
      width: 195px;
      height: auto;
      margin-right: 35px;

      img {
        width: 100%;
        display: block;
      }
    }

    h2 {
      @include font-bc-alphapipe-rb-regular;
      font-size: 50px;
      color: #949898;
      line-height: 1em;
      display: inline-block;

      span {
        font: inherit;
        @include font-bc-alphapipe-rb-bold;
        color: #20b6b2;
        line-height: inherit;
      }
    }
  }

  div.user {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      font-size: 18px;
      @include font-lato-semibold;
      color: #949898;
      line-height: 1em;
      display: block;
      margin-right: 18px;
    }

    span {
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      @include font-lato-semibold;
      font-size: 14px;
      color: #20b6b2;
      line-height: 1em;
      background: #fff;
      border-radius: 39px;
    }
  }
}
