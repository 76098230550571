@import './mixin';
@import 'fonts';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

html,
body {
  min-width: 100vw;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body {
  @include font-lato;
  color: #333333;
  font-size: 14px;
  background-blend-mode: overlay, normal, normal;
  background: rgb(224, 224, 224);
  background: linear-gradient(180deg, rgba(224, 224, 224, 1) 0%, rgba(246, 246, 246, 1) 100%);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @include bp-tablet-max {
    * {
      display: none;
    }
  }

  div.container {
    padding: 9px 32px 20px;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;

    div.content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      main {
        width: 100%;
        padding: 0;
      }
    }

    &.show-side-nav {
      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -3489px;
        width: 3000px;
        height: 100%;
        top: 0;
        z-index: -1;
        background-blend-mode: overlay, normal, normal;
        background: #f0f0f0;
        background: linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%);
      }

      div.content {
        main {
          width: calc(100% - 162px);
          padding: 0 0 0 105px;
        }
      }
    }
  }

  span[role='alert'] {
    color: #fb6565;
    font-size: 12px;
    @include font-lato-italic;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    outline: none !important;
  }

  div,
  span,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  caption,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    @include font-lato;
  }

  i {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
    @include font-lato;
  }

  nav ul {
    list-style: none;
    @include font-lato;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    @include font-lato;
  }

  img {
    max-width: 100%;
  }

  ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
  }

  mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: 500;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title],
  dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }

  input,
  select {
    vertical-align: middle;
    transition: all 0.25s ease 0s;
    @include font-lato-light;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  textarea {
    resize: none;
    transition: all 0.25s ease 0s;
    @include font-lato-light;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  button {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-weight: 300;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    padding: 0;
    border: none;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  a {
    transition: all 0.2s ease 0s;
    cursor: pointer;

    @include bp-desktop-min {
      opacity: 1;
    }

    &:hover {
      @include bp-desktop-min {
        opacity: 0.75;
        text-decoration: none;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &,
    * {
      @include font-lato-bold;
    }
  }

  strong {
    font-weight: 500;
    font-family: inherit;
  }

  ::-webkit-input-placeholder {
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  :-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  ::-moz-placeholder {
    opacity: 1;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  :-ms-input-placeholder {
    opacity: 1;
    -ms-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }

  ::placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }

  *:focus::-webkit-input-placeholder {
    opacity: 0;
  }

  *:focus:-moz-placeholder {
    opacity: 0;
  }

  *:focus::-moz-placeholder {
    opacity: 0;
  }

  *:focus:-ms-input-placeholder {
    opacity: 0;
  }

  *:focus::placeholder {
    opacity: 0;
  }
}

@include bp-desktop-min {
  ::-webkit-scrollbar {
    width: 5px;
    background: #0f1b37;

    &-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 5px;
    }
  }
}

html.modal-open {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

.modal {
  background: rgba(232, 232, 232, 0.84);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  animation-duration: 0.25s;
  animation-name: fadeIn;

  &.fadeOut {
    animation-name: fadeOut;
  }

  .backdrop {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .close {
    position: absolute;
    border: none;
    background: none;
    right: 10px;
    top: 12px;
    color: #20b6b2;
    font-size: 22px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    line-height: 1em;
  }

  .wrap {
    position: relative;
    z-index: 50;
  }

  &.video {
    .close {
      z-index: 200;
      text-shadow: 0px 0px 5px #000;
      color: #fff;
      font-size: 35px;
    }

    .wrap {
      display: block;
      width: 100vw;
      max-width: 177.78vh;
      height: 56.25vw;
      max-height: 100vh;
      background-color: black;
      margin: 0 auto;

      .video {
        position: relative;
        width: 100%;
        height: 100%;

        > div {
          width: 100% !important;
          height: 100% !important;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea,
select {
  @include trans;

  &:focus {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.45);
  }
}
