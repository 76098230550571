@import '../../assets/styles/mixin';

div.splash {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;
  animation-duration: 0.25s;
  animation-name: fadeIn;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.4);
    z-index: 10;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    margin: auto;
    background: none;
    z-index: 20;
    width: 150px;
    height: 150px;
  }
}
